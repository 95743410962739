import React from 'react'
import Layout from '../components/Layout'
import WycenaTransportu from '../page/WycenaTransportu/WycenaTransportu'

const WycenaTransportuPage = () => (
  <Layout>
    <WycenaTransportu />
  </Layout>
)

export default WycenaTransportuPage
import React from 'react'
import FormService from '../../page/Services/FormService/FormService.js'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import { globals, s, colors, alpha } from '../../style'

const WycenaTransportu = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <div css={[sOuter]}>
        <div css={sInner}>
          <div css={[sText, sTextIsBlog]}>
            <div css={{ position: 'relative' }}>
              <h1 css={[sTitle, sTitleIsBlog]}>Wyceń Transport</h1>
              {/* <span css={sBack}>Lorem ipsum</span> */}
            </div>
            <p css={[sDesc, sDescIsBlog]}>Szybka wycena transportu, realizujemy transport międzynarodowy FTL oraz LTL. Sprawdź naszą ofertę!</p>
          </div>
          <FormService pages={'service'} />
        </div>
      </div>
    </>
  )
}

const sOuter = {
  padding: '150px 50px 0',
  [s.sm_down]: {
    padding: '100px 50px 0',
  },
  [s.xs]: {
    padding: '100px 0px 0',
  },
}

const sInner = {
  animation: 'slide-panel 0.6s ease-out',
  maxWidth: globals.maxWidth - 100,
  width: '100%',
  padding: 100,
  position: 'relative',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [s.md_down]: {
    padding: 50,
    // flexDirection: 'column',
  },
  [s.sm_down]: {
    flexDirection: 'column',
  },
  [s.xs]: {
    padding: 50,
  },
}

export const sText = {
  textAlign: 'center',
  // position: 'absolute',
  zIndex: 2,
  // left: '50%',
  // bottom: 50,
  // transform: 'translate(-50%, 0%)',
  maxWidth: '100vw',

  padding: '0 50px 100px',
  [s.sm_down]: {
    padding: '0 25px 50px',
    width: '100%',
  },
  [s.sm]: {
    padding: '0 0 75px',
    // bottom: 0,
    // transform: 'translate(-50%, 100%)',
  },
  [s.md_only]: {
    width: '75%',
    // bottom: 0,
    // transform: 'translate(-50%, 75%)',
  },
  [s.lg]: {
    // bottom: 0,
    // transform: 'translate(-50%, 50%)',
  },
  [s.xl]: {
    padding: '0 0 100px',
  },
}

const sTextIsBlog = {
  [s.sm]: {
    width: '100%',
    // transform: 'translate(-50%, 0%)',
    // bottom: 150,
  },
  [s.md_only]: {
    // transform: 'translate(-50%, 0%)',
    // bottom: 150,
  },
  [s.lg]: {
    // transform: 'translate(-50%, 0%)',
    // bottom: 150,
  },
}

export const sTitle = {
  textTransform: 'uppercase',
  fontSize: '3.5rem',
  margin: '25px 0',
  lineHeight: '1em',
  [s.sm_down]: {
    fontSize: '2.5rem',
  },
}

const sTitleIsBlog = {
  [s.ss]: {
    fontSize: '2rem',
  },
}

export const sBack = {
  textTransform: 'uppercase',
  fontFamily: 'Staatliches',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '6.25rem',
  opacity: 0.12,
  letterSpacing: '0.33em',
  width: '100%',
  [s.sm_down]: {
    fontSize: '6.25rem',
    letterSpacing: '0em',
  },
}

export const sDesc = {
  fontSize: '1rem',
  lineHeight: '1.6em',
  opacity: 0.5,
  [s.sm_down]: {
    fontSize: '0.875rem',
  },
}

const sDescIsBlog = {
  [s.lg]: { maxWidth: `calc(${globals.maxWidth}px - 100px)`, margin: '0 auto' },
  [s.xl]: { maxWidth: `calc(${globals.maxWidth}px - 400px)`, margin: '0 auto' },
}

export default WycenaTransportu